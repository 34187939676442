import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { IUrlGeneratorQuery } from '../../../../../../../../base/common/helpers/IUrlGenerator';
import type { LocationDetailWithGeoApiModel } from '../../../../../../models/api/location/LocationDetailWithGeoApiModel';
import type { BaseLatLngUsersEntitiesQuery } from '../../../../../../models/queries/base/BaseLatLngUsersEntitiesQuery';
import type { BaseLatLngUsersEntitiesQueryFilters } from '../../../../../../models/queries/base/BaseLatLngUsersEntitiesQueryFilters';
import { mapLocationActionCreators } from '../../../../../../store/modules/map/mapLocationReducer';
import type { IMapAwareState } from '../../../../../../store/modules/map/models/IMapAwareState';

export function isSlugsEqual(a: Readonly<string[]> | undefined, b: string[] | undefined): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

interface UseMapLocationDataProps {
  urlGenerator: IUrlGeneratorQuery<BaseLatLngUsersEntitiesQuery, BaseLatLngUsersEntitiesQueryFilters>,
}

const useMapLocationData = ({
  urlGenerator,
}: UseMapLocationDataProps) => {

  const locationSlugs = useSelector((state: IMapAwareState): string[] | undefined => state.mapLocation?.query?.filters?.localNameSlugs);
  const locationData = useSelector((state: IMapAwareState): LocationDetailWithGeoApiModel | undefined => state.mapLocation?.data);

  const dispatch: ThunkDispatch<never, never, Action> = useDispatch();

  const locationQuery = urlGenerator.query.location;

  useEffect(() => {
    if (locationQuery) {
      if (locationQuery.id) {
        if (locationQuery.id !== locationData?.id) {
          dispatch(mapLocationActionCreators.fetchByIdAsync(locationQuery.id));
        }
      } else if (locationQuery.slugs && locationQuery.slugs.length > 0) {
        if (!isSlugsEqual(locationQuery.slugs, locationSlugs)) {
          dispatch(mapLocationActionCreators.fetchByQueryAsync({
            filters: {
              localNameSlugs: [...locationQuery.slugs],
            },
          }));
        }
      } else {
        dispatch(mapLocationActionCreators.clear());
      }
    } else {
      if (locationData) {
        dispatch(mapLocationActionCreators.clear());
      }
    }
  }, [dispatch, locationData, locationQuery, locationSlugs]);
};

export default useMapLocationData;
