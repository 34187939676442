import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapHelpers from '../../../../../../helpers/map/MapHelpers';
import type { IMapAdapter } from '../../../../../../helpers/map/interface/IMapAdapter';
import type { IHomePageUrlGenerator } from '../../../../../../hooks/IHomePageUrlGenerator';
import type { MapParamsQuery } from '../../../../../../models/system/map/queries/MapParamsQuery';
import { mapSetParams } from '../../../../../../store/modules/map/mapReducer';
import type { IMapAwareState } from '../../../../../../store/modules/map/models/IMapAwareState';

interface UseMainMapKeepCoordinatesProps {
  urlGenerator: IHomePageUrlGenerator;
  mapAdapter: IMapAdapter;
}

function useMainMapKeepCoordinates({
  urlGenerator,
  mapAdapter,
}: UseMainMapKeepCoordinatesProps) {

  const mapParams = useSelector((state: IMapAwareState): MapParamsQuery | undefined => state.map.params);

  const dispatch = useDispatch();

  // Keep map coordinates from URL in sync with store
  useEffect(() => {
    if (mapAdapter && urlGenerator.query.map && !urlGenerator.query.location?.id && !urlGenerator.query.location?.slugs) {
      if (MapHelpers.isMapParamsSame(urlGenerator.query.map, MapHelpers.getMapParamsFromMapAdapter(mapAdapter))) {
        if (!MapHelpers.isMapParamsSame(urlGenerator.query.map, mapParams)) {
          dispatch(mapSetParams(urlGenerator.query.map));
        }
      }
    }
  }, [dispatch, mapAdapter, mapParams, urlGenerator.query.location, urlGenerator.query.map]);

}

export default useMainMapKeepCoordinates;
