import BaseMapRepositoryOpenStreetMap from './base/BaseMapRepositoryOpenStreetMap';

class SingletonMapRepositoryOpenStreetMap extends BaseMapRepositoryOpenStreetMap {

  private static instance: SingletonMapRepositoryOpenStreetMap;

  public static getInstance(): SingletonMapRepositoryOpenStreetMap {
    if (!this.instance) {
      this.instance = new SingletonMapRepositoryOpenStreetMap();
    }

    return this.instance;
  }

  private constructor() {
    super();
  }

  public isMapInitialized(): boolean {
    return this.mapAdapter != null;
  }
}

export default SingletonMapRepositoryOpenStreetMap;
