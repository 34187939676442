import loadable from '@loadable/component';
import React, { type ReactElement } from 'react';
import type OpenStreetMapLibrary from './OpenStreetMapLibrary';
import type OpenStreetMapLibraryPluginGestureHandling from './OpenStreetMapLibraryPluginGestureHandling';

const Leaflet = loadable.lib<unknown, OpenStreetMapLibrary>(() => import('leaflet'), { ssr: false });
const LeafletGestureHandling = loadable.lib<unknown, OpenStreetMapLibraryPluginGestureHandling>(() => import('leaflet-gesture-handling'), { ssr: false });

interface MapLibraryLoaderLeafletProps {
  children: (mapLibrary: OpenStreetMapLibrary, plugin: OpenStreetMapLibraryPluginGestureHandling) => ReactElement;
}

// let renderCounter = 0;

const MapLibraryLoaderLeaflet: React.FC<MapLibraryLoaderLeafletProps> = ({ children }: MapLibraryLoaderLeafletProps) => {

  // console.debug(`Render MapLibraryLoaderLeaflet ${++renderCounter}`);

  return (
    <Leaflet>
      {(leafletMapLibrary) => {
        return (
          <LeafletGestureHandling>
            {(leafletMapGestureHandlingLibrary) => {
              return (
                <>
                  {children(leafletMapLibrary, leafletMapGestureHandlingLibrary)}
                </>
              );
            }}
          </LeafletGestureHandling>
        );
      }}
    </Leaflet>
  );
};

export default MapLibraryLoaderLeaflet;
