import React, { type CSSProperties, type PropsWithChildren } from 'react';
import styles from './MapWrapper.module.scss';
import './MapWrapper.scss';

interface MapWrapperProps {
  className?: string | undefined;
  style?: CSSProperties;
}

// let renderCounter = 0;

const MapWrapper: React.FC<PropsWithChildren<MapWrapperProps>> = ({ className, style, children }: PropsWithChildren<MapWrapperProps>) => {

  // console.debug(`Render MapWrapper ${++renderCounter}`);

  return (
    <div
      className={`${styles['app-div']} map-wrapper${className ? ` ${className}` : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default MapWrapper;
