import loadable from '@loadable/component';
import React, { type ErrorInfo, type PropsWithChildren } from 'react';

const MainMapError = loadable(() => import(/* webpackChunkName: "main-map-error-content" */'./MainMapError'));

type MainMapErrorBoundaryProps = PropsWithChildren;

interface MainMapErrorBoundaryState {
  hasError: boolean;
}

class MainMapErrorBoundary extends React.Component<MainMapErrorBoundaryProps, MainMapErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  override render() {
    if (this.state.hasError) {
      return (
        <MainMapError />
      );
    }

    return this.props.children;
  }
}

export default MainMapErrorBoundary;
