import React, { forwardRef, useCallback, useImperativeHandle, useState, type PropsWithChildren, type Ref } from 'react';
import { useSelector } from 'react-redux';
import SingletonMapRepositoryOpenStreetMap from '../../../../../client/repositories/SingletonMapRepositoryOpenStreetMap';
import type OpenStreetMapMapAdapter from '../../../../helpers/map/OpenStreetMapMapAdapter';
import type { IHomePageUrlGenerator } from '../../../../hooks/IHomePageUrlGenerator';
import type { LocationDetailWithGeoApiModel } from '../../../../models/api/location/LocationDetailWithGeoApiModel';
import type { MapCenterAndZoomOrBoundsModel } from '../../../../models/system/map/MapCenterAndZoomOrBoundsModel';
import type { MapParamsQuery } from '../../../../models/system/map/queries/MapParamsQuery';
import { DEFAULT_BOUNDING_BOX_E, DEFAULT_BOUNDING_BOX_N, DEFAULT_BOUNDING_BOX_S, DEFAULT_BOUNDING_BOX_W } from '../../../../store/modules/map/mapReducer';
import type { IMapAwareState } from '../../../../store/modules/map/models/IMapAwareState';
import type OpenStreetMapLibrary from './OpenStreetMapLibrary';
import type OpenStreetMapLibraryPluginGestureHandling from './OpenStreetMapLibraryPluginGestureHandling';

function helperGetInitialCenterAndZoomOrBounds(
  urlGenerator: IHomePageUrlGenerator,
  mapParamsQuery: MapParamsQuery | undefined,
  location: LocationDetailWithGeoApiModel | undefined
): MapCenterAndZoomOrBoundsModel {

  const { center: { lat: mapParamsCenterLat, lng: mapParamsCenterLng } = { lat: undefined, lng: undefined }, zoom: mapParamsZoom } = mapParamsQuery || {};
  const { boundingboxNorth, boundingboxEast, boundingboxSouth, boundingboxWest } = location || {};

  if (urlGenerator.query.map && urlGenerator.query.map.center && urlGenerator.query.map.zoom) {
    // console.log('Initial from query');
    return {
      center: urlGenerator.query.map.center,
      zoom: urlGenerator.query.map.zoom,
    };
  } else if (mapParamsCenterLat && mapParamsCenterLng && mapParamsZoom) {
    // console.log('Initial from state map params');
    return {
      center: {
        lat: mapParamsCenterLat,
        lng: mapParamsCenterLng,
      },
      zoom: mapParamsZoom,
    };
  } else if (boundingboxNorth && boundingboxEast && boundingboxSouth && boundingboxWest) {
    // console.log('Initial from state location bounding box');
    return {
      bounds: {
        n: boundingboxNorth,
        e: boundingboxEast,
        s: boundingboxSouth,
        w: boundingboxWest,
      }
    };
  } else {
    // console.log('Initial from state country bounding box');
    return {
      bounds: {
        n: DEFAULT_BOUNDING_BOX_N,
        e: DEFAULT_BOUNDING_BOX_E,
        s: DEFAULT_BOUNDING_BOX_S,
        w: DEFAULT_BOUNDING_BOX_W,
      }
    };
  }
}

interface MapContainerIntrinsicOpenStreetMapMainProps {
  urlGenerator: IHomePageUrlGenerator;
  mapLibrary: OpenStreetMapLibrary;
  mapLibraryPlugin: OpenStreetMapLibraryPluginGestureHandling;
}

// let renderCounter = 0;

function MapContainerIntrinsicOpenStreetMapMain({
  urlGenerator,
  mapLibrary,
  mapLibraryPlugin,
}: PropsWithChildren<MapContainerIntrinsicOpenStreetMapMainProps>, forwardedRef: Ref<OpenStreetMapMapAdapter | undefined>) {

  const [mapAdapter, setMapAdapter] = useState<OpenStreetMapMapAdapter>();

  const mapParams = useSelector((state: IMapAwareState) => state.map?.params);
  const location = useSelector((state: IMapAwareState) => state.mapLocation?.data);

  useImperativeHandle(forwardedRef, () => mapAdapter, [mapAdapter]);

  // console.debug(`Render MapContainerIntrinsicOpenStreetMap ${++renderCounter}`);

  const mapRef = useCallback((div: HTMLDivElement | null) => {
    if (!mapAdapter && div) {
      const initialCenterAndZoomOrBounds = helperGetInitialCenterAndZoomOrBounds(urlGenerator, mapParams, location);

      const mapAdapter = SingletonMapRepositoryOpenStreetMap.getInstance().initMapOpenStreetMapWithLib(
        div,
        initialCenterAndZoomOrBounds,
        mapLibrary,
        mapLibraryPlugin,
      );

      mapAdapter.initEvents();

      setMapAdapter(mapAdapter);
    }
  }, [location, mapAdapter, mapLibrary, mapLibraryPlugin, mapParams, urlGenerator]);

  return (
    <div ref={mapRef} className='map-container' />
  );
}

export default forwardRef(MapContainerIntrinsicOpenStreetMapMain);
