import _debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';
import type OpenStreetMapMapAdapter from '../../../../../../helpers/map/OpenStreetMapMapAdapter';
import mapRedirect from '../../../../../../helpers/mapRedirect';
import type { IHomePageUrlGenerator } from '../../../../../../hooks/IHomePageUrlGenerator';

const TIMEOUT_ADD_COORDINATES_TO_URL = 500; // Give user time to move/zoom map again without pushing to browser history 

interface UseMainMapMovedRedirectProps {
  urlGenerator: IHomePageUrlGenerator,
  mapAdapter: OpenStreetMapMapAdapter,
}

function useMainMapMovedRedirect({
  urlGenerator,
  mapAdapter,
}: UseMainMapMovedRedirectProps) {

  const mapHandlerSetCoordinatesToUrl = useMemo(() => {
    return _debounce((mapAdapter: OpenStreetMapMapAdapter) => {
      mapRedirect(urlGenerator, mapAdapter, false);
    }, TIMEOUT_ADD_COORDINATES_TO_URL);
  }, [urlGenerator]);

  // Keep map coordinates from map in sync with URL after user invoke bounds change
  // When map change is invoke by system, URL should be handled by system
  // Only user actions on map should be redirected here
  useEffect(() => {
    mapAdapter.onBoundsChangedByUser(() => {
      mapHandlerSetCoordinatesToUrl.cancel();
      mapHandlerSetCoordinatesToUrl(mapAdapter);
    });

    return (): void => {
      mapHandlerSetCoordinatesToUrl.cancel();
      mapAdapter.onBoundsChangedByUser(undefined);
    };
  }, [mapAdapter, mapHandlerSetCoordinatesToUrl]);

  // Cancel debounced redirect if map is moved
  // When there is a pending redirect, cancel it when map has moved
  // Redirection should be made to the latest map coordinates after users finished moving map
  // This prevents from populating browser history with multiple map moves in shor period of time (TIMEOUT_ADD_COORDINATES_TO_URL)
  useEffect(() => {
    const listenerMoved = mapAdapter.onMoved(() => {
      mapHandlerSetCoordinatesToUrl.cancel();
    });

    return (): void => {
      listenerMoved.remove();
    };
  }, [mapAdapter, mapHandlerSetCoordinatesToUrl]);
}

export default useMainMapMovedRedirect;
