import loadable from '@loadable/component';
import React, { useCallback, useRef, useState } from 'react';
import type { IMapAdapterOpenStreetMap } from '../../../../../helpers/map/interface/IMapAdapterOpenStreetMap';
import type { IHomePageUrlGenerator } from '../../../../../hooks/IHomePageUrlGenerator';
import MapSearchPlaceInput from './MapSearchPlaceInput';
import type { OpenStreetMapMapSearchPlaceOpenStreetMapResultsRef } from './OpenStreetMapMapSearchPlaceOpenStreetMapResults';

const OpenStreetMapMapSearchPlaceOpenStreetMapResults = loadable(() => import(
  /* webpackChunkName: "map-main-search-results" */
  './OpenStreetMapMapSearchPlaceOpenStreetMapResults'
));

interface OpenStreetMapMapSearchPlaceOpenStreetMapContainerProps {
  urlGenerator: IHomePageUrlGenerator;
  mapAdapter: IMapAdapterOpenStreetMap | undefined;
}

// let renderCounter = 0;

const OpenStreetMapMapSearchPlaceOpenStreetMapContainer: React.FC<OpenStreetMapMapSearchPlaceOpenStreetMapContainerProps> = ({
  urlGenerator,
  mapAdapter
}: OpenStreetMapMapSearchPlaceOpenStreetMapContainerProps) => {

  // console.debug(`Render OpenStreetMapMapSearchPlaceOpenStreetMapContainer ${++renderCounter}`);

  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef<OpenStreetMapMapSearchPlaceOpenStreetMapResultsRef>(null);

  const handleOnKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    return ref.current?.onKeyDown(event);
  }, []);

  return (
    <MapSearchPlaceInput
      initialValue={selectedValue}
      isLoading={isLoading}
      isDisabled={!mapAdapter}
      onChange={setInputValue}
      onKeyDown={handleOnKeyDown}
    >
      {mapAdapter && inputValue && (
        <OpenStreetMapMapSearchPlaceOpenStreetMapResults
          urlGenerator={urlGenerator}
          mapAdapter={mapAdapter}
          inputValue={inputValue}
          onIsLoading={setIsLoading}
          handleOnSelect={setSelectedValue}
          ref={ref}
        />
      )}
    </MapSearchPlaceInput>
  );
};

export default OpenStreetMapMapSearchPlaceOpenStreetMapContainer;
