import type { FitBoundsOptions, LatLngBoundsLiteral, Map } from 'leaflet';
import type OpenStreetMapLibrary from '../../components/shared/elements/map/OpenStreetMapLibrary';
import type { MapBoundsSimpleModel } from '../../models/system/map/MapBoundsSimpleModel';
import type { MapLatLngModel } from '../../models/system/map/MapLatLngModel';
import OpenStreetMapMapListenerAdapter from './OpenStreetMapMapListenerAdapter';
import MapAdapter from './base/MapAdapter';
import type { IMapAdapterOpenStreetMap } from './interface/IMapAdapterOpenStreetMap';

class OpenStreetMapMapAdapter extends MapAdapter<
  Map,
  OpenStreetMapMapListenerAdapter
> implements IMapAdapterOpenStreetMap {

  public readonly mapLibrary: OpenStreetMapLibrary;

  private onBoundsChangedByUserHandler: (() => void) | undefined;

  private isOnMoveEndEventsPaused = false;

  // private latestMapParams: MapParamsQuery | undefined;

  private onMoveEndHandler: OpenStreetMapMapListenerAdapter | undefined;

  constructor(adaptedMap: Map, mapLibrary: OpenStreetMapLibrary) {
    super(adaptedMap);

    this.mapLibrary = mapLibrary;
  }

  public getMapLibraryOpenStreetMap(): OpenStreetMapLibrary {
    return this.mapLibrary;
  }

  public override initEvents(): void {
    if (this.onMoveEndHandler) {
      throw new Error('OpenStreetMapMapAdapter is already initialized');
    }

    this.onMoveEndHandler = this.onEvent('moveend', () => {
      this.handleOnMoveEnd();
    });
  }

  public override cleanupEvents(): void {
    if (!this.onMoveEndHandler) {
      throw new Error('OpenStreetMapMapAdapter has not been initialized');
    }

    this.onMoveEndHandler.remove();
  }

  private handleOnMoveEnd() {
    if (!this.isOnMoveEndEventsPaused) {
      // const newMapParams: MapParamsQuery = {
      //   center: this.getCenter(),
      //   zoom: this.getZoom(),
      //   bounds: this.getBounds(),
      // };

      // if (DebugHelper.isDebug()) {
      //   console.log('-- emit');
      //   const diff = MapHelpers.calculateMapParamsQueryDiff(newMapParams, this.latestMapParams);
      //   console.log('Diff after user bounds changed', MapHelpersDebug.formatMapParamsQuery(diff));
      // }

      if (this.onBoundsChangedByUserHandler) {
        this.onBoundsChangedByUserHandler();
      }
      // this.latestMapParams = newMapParams;
    } else {
      // if (DebugHelper.isDebug()) {
      //   console.debug('-- locked', this.isOnMoveEndEventsPaused);

      //   const newMapParams: MapParamsQuery = {
      //     center: this.getCenter(),
      //     zoom: this.getZoom(),
      //     bounds: this.getBounds(),
      //   };

      //   const diff = MapHelpers.calculateMapParamsQueryDiff(newMapParams, this.latestMapParams);
      //   console.log('Locked change', MapHelpersDebug.formatMapParamsQuery(diff));
      // }
    }
  }

  public getCenter = (): MapLatLngModel => {
    const center = this.adaptedMap.getCenter().wrap();

    const lat = this.round(center.lat);
    const lng = this.round(center.lng);

    return {
      lat: lat,
      lng: lng,
    };
  };

  public getZoom = (): number => {
    return this.adaptedMap.getZoom();
  };

  public getBounds = (): MapBoundsSimpleModel => {
    const bounds = this.adaptedMap.getBounds();

    return {
      n: this.roundBounds(bounds.getNorth()),
      s: this.roundBounds(bounds.getSouth()),
      e: this.roundBounds(bounds.getEast()),
      w: this.roundBounds(bounds.getWest()),
    };
  };

  public setCenter = (center: MapLatLngModel): void => {
    this.isOnMoveEndEventsPaused = true;
    this.adaptedMap.stop();
    this.adaptedMap.panTo(center);
    this.isOnMoveEndEventsPaused = false;
    // this.saveLatestStateChangedBySystem();
  };

  public setZoom = (zoom: number): void => {
    this.adaptedMap.stop();
    this.adaptedMap.setZoom(zoom);
    // this.saveLatestStateChangedBySystem();
  };

  public override setCenterAndZoom = (center: MapLatLngModel, zoom?: number): void => {
    this.isOnMoveEndEventsPaused = true;
    this.adaptedMap.stop();
    this.adaptedMap.flyTo(center, zoom, {
      animate: false,
      noMoveStart: true,
      duration: 0,
    });
    this.isOnMoveEndEventsPaused = false;
    // this.saveLatestStateChangedBySystem();
  };

  public fitToBounds(bounds: MapBoundsSimpleModel): void {
    const latLngBoundsExpression: LatLngBoundsLiteral = [
      [
        bounds.n,
        bounds.w,
      ],
      [
        bounds.s,
        bounds.e,
      ],
    ];

    const fitBoundsOptions: FitBoundsOptions = {
      animate: false,
      duration: 0,
    };

    this.isOnMoveEndEventsPaused = true;
    this.adaptedMap.stop();
    this.adaptedMap.fitBounds(latLngBoundsExpression, fitBoundsOptions);
    this.isOnMoveEndEventsPaused = false;
    // this.saveLatestStateChangedBySystem();
  }

  public remove = (): void => {
    this.adaptedMap.remove();
  };

  public onEvent = (eventName: string, handler: () => void): OpenStreetMapMapListenerAdapter => {
    const adaptedListener = this.adaptedMap.on(eventName, handler);
    const listener = new OpenStreetMapMapListenerAdapter(eventName, handler, adaptedListener, this.adaptedMap);

    return listener;
  };

  public onCenterChanged = (handler: () => void): OpenStreetMapMapListenerAdapter => {
    return this.onEvent('moveend', handler);
  };

  public onZoomChanged = (handler: () => void): OpenStreetMapMapListenerAdapter => {
    return this.onEvent('zoomend', handler);
  };

  public onBoundsChangedByUser = (handler: (() => void) | undefined): void => {
    this.onBoundsChangedByUserHandler = handler;
  };

  public onMoved = (handler: () => void): OpenStreetMapMapListenerAdapter => {
    return this.onEvent('movestart', handler);
  };

  private round(number: number): number {
    // return number;
    return this.mapLibrary.Util.formatNum(number, 15);
  }

  private roundBounds(number: number): number {
    // return number;
    return this.mapLibrary.Util.formatNum(number, 14);
  }

  // private saveLatestStateChangedBySystem() {
  //   const mapParamsQuery: MapParamsQuery = {
  //     center: this.getCenter(),
  //     zoom: this.getZoom(),
  //     bounds: this.getBounds(),
  //   };

  //   this.latestMapParams = mapParamsQuery;
  // }
}

export default OpenStreetMapMapAdapter;
