import type { LatLngBoundsExpression, Map, MapOptions, TileLayerOptions } from 'leaflet';
import Utils from '../../../../base/common/helpers/Utils';
import type OpenStreetMapLibrary from '../../../common/components/shared/elements/map/OpenStreetMapLibrary';
import type OpenStreetMapLibraryPluginGestureHandling from '../../../common/components/shared/elements/map/OpenStreetMapLibraryPluginGestureHandling';
import OpenStreetMapMapAdapter from '../../../common/helpers/map/OpenStreetMapMapAdapter';
import type { MapCenterAndZoomOrBoundsModel } from '../../../common/models/system/map/MapCenterAndZoomOrBoundsModel';
import type { MapLatLngModel } from '../../../common/models/system/map/MapLatLngModel';
import { DEFAULT_MAP_ZOOM } from '../../../common/store/modules/map/mapReducer';

abstract class BaseMapRepositoryOpenStreetMap {

  protected mapOpenStreetMap?: Map;

  protected mapAdapter?: OpenStreetMapMapAdapter;

  protected get defaultZoom(): number {
    return DEFAULT_MAP_ZOOM;
  }

  public getMapOpenStreetMap(): Map {
    if (!this.mapOpenStreetMap) {
      throw new Error('Map adapter for Open Street Map is not initialized.');
    } else {
      return this.mapOpenStreetMap;
    }
  }

  public getMapAdapter(): OpenStreetMapMapAdapter {
    if (!this.mapAdapter) {
      throw new Error('Map adapter is not initialized.');
    } else {
      return this.mapAdapter;
    }
  }

  public initMap(
    element: HTMLElement,
    center: MapLatLngModel,
    zoom: number = DEFAULT_MAP_ZOOM,
    mapLibrary?: OpenStreetMapLibrary,
    mapLibraryPlugin?: OpenStreetMapLibraryPluginGestureHandling
  ): OpenStreetMapMapAdapter {
    if (!mapLibrary) {
      throw new Error('Missing map lib.');
    }

    this.initMapOpenStreetMapWithLib(element, { center, zoom }, mapLibrary, mapLibraryPlugin);

    return this.getMapAdapter();
  }

  public initMapOpenStreetMapWithLib(
    element: HTMLElement,
    centerAndZoomOrBounds: MapCenterAndZoomOrBoundsModel,
    mapLibrary: OpenStreetMapLibrary,
    mapLibraryPlugin: OpenStreetMapLibraryPluginGestureHandling | undefined
  ): OpenStreetMapMapAdapter {
    const options: MapOptions & { gestureHandling: boolean; } = {
      closePopupOnClick: false,
      doubleClickZoom: false,
      gestureHandling: false,
    };

    if (Utils.isMobile() && mapLibraryPlugin) {
      mapLibrary.Map.addInitHook('addHandler', 'gestureHandling', mapLibraryPlugin.GestureHandling);
      options.gestureHandling = true;
    }

    try {
      // console.log('+++++++++++++++++++ CREATING NEW MAP +++++++++++++++++++++++', centerAndZoomOrBounds);
      this.mapOpenStreetMap = new mapLibrary.Map(element, options);
    } catch (error) {
      if (error instanceof Error && error.message === 'Map container is already initialized.' && this.mapOpenStreetMap) {
        // in React.StrictMode tree is rendered twice, second render cousing double map initialization
      } else {
        throw error;
      }
    }

    if (centerAndZoomOrBounds.bounds) {
      const bounds: LatLngBoundsExpression = new mapLibrary.LatLngBounds(
        {
          lat: centerAndZoomOrBounds.bounds.s,
          lng: centerAndZoomOrBounds.bounds.w,
        },
        {
          lat: centerAndZoomOrBounds.bounds.n,
          lng: centerAndZoomOrBounds.bounds.e,
        }
      );

      this.mapOpenStreetMap.fitBounds(bounds);
    } else {
      this.mapOpenStreetMap.setView(centerAndZoomOrBounds.center, centerAndZoomOrBounds.zoom);
    }

    const tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const tileLayerOptions: TileLayerOptions = {
      minZoom: 4,
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/" target="_blank" tabindex="-1">OpenStreetMap</a> contributors',
      // id: 'mapbox/streets-v11',
      // tileSize: 512,
      // zoomOffset: -1
    };

    const tileLayer = new mapLibrary.TileLayer(tileLayerUrl, tileLayerOptions);

    tileLayer.addTo(this.mapOpenStreetMap);

    this.mapAdapter = new OpenStreetMapMapAdapter(this.mapOpenStreetMap, mapLibrary);

    return this.mapAdapter;
  }
}

export default BaseMapRepositoryOpenStreetMap;
