import React, { forwardRef, type Ref } from 'react';
import type OpenStreetMapMapAdapter from '../../../../helpers/map/OpenStreetMapMapAdapter';
import type { IHomePageUrlGenerator } from '../../../../hooks/IHomePageUrlGenerator';
import MapContainerIntrinsicOpenStreetMapMain from './MapContainerIntrinsicOpenStreetMapMain';
import MapLibraryLoaderLeaflet from './MapLibraryLoaderLeaflet';

interface MapContainerOpenStreetMapMainProps {
  urlGenerator: IHomePageUrlGenerator;
}

// let renderCounter = 0;

function MapContainerOpenStreetMapMain({
  urlGenerator,
}: MapContainerOpenStreetMapMainProps, forwardedRef: Ref<OpenStreetMapMapAdapter>) {

  // console.debug(`Render MapContainerOpenStreetMap ${++renderCounter}`);

  return (
    <MapLibraryLoaderLeaflet>
      {(mapLibrary, plugin) => (
        <MapContainerIntrinsicOpenStreetMapMain
          urlGenerator={urlGenerator}
          mapLibrary={mapLibrary}
          mapLibraryPlugin={plugin}
          ref={forwardedRef}
        />
      )}
    </MapLibraryLoaderLeaflet>
  );
}

export default forwardRef(MapContainerOpenStreetMapMain);
