import useMainMapKeepCoordinates from '../../../../../../framework/gis/common/components/shared/elements/map/main/hooks/useMainMapKeepCoordinates';
import useMainMapLocationData from '../../../../../../framework/gis/common/components/shared/elements/map/main/hooks/useMainMapLocationData';
import useMainMapMovedRedirect from '../../../../../../framework/gis/common/components/shared/elements/map/main/hooks/useMainMapMovedRedirect';
import type OpenStreetMapMapAdapter from '../../../../../../framework/gis/common/helpers/map/OpenStreetMapMapAdapter';
import type { IHomePageUrlGenerator } from '../../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';

interface MainMapShownActionsProps {
  urlGenerator: IHomePageUrlGenerator;
  mapAdapter: OpenStreetMapMapAdapter;
}

// let renderCounter = 0;

const MainMapShownActions: React.FC<MainMapShownActionsProps> = ({
  urlGenerator,
  mapAdapter,
}: MainMapShownActionsProps) => {

  // console.debug(`Render MainMapShownActions ${++renderCounter}`);

  useMainMapKeepCoordinates({ urlGenerator, mapAdapter }); // Form Yes    Detail No     List Yes
  useMainMapMovedRedirect({ urlGenerator, mapAdapter }); //   Form Yes    Detail No     List Yes
  useMainMapLocationData({ urlGenerator }); //                Form Maybe  Detail Yes    List Yes

  return null;
};

export default MainMapShownActions;
