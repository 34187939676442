import React, { useCallback } from 'react';
import type { HomePageQuery } from '../../../../../../../../common/models/system/home/queries/HomePageQuery';
import type { DeepReadonly } from '../../../../../../../base/common/constants/types/UtilityTypes';
import type { IMapAdapterOpenStreetMap } from '../../../../../helpers/map/interface/IMapAdapterOpenStreetMap';
import type { IHomePageUrlGenerator } from '../../../../../hooks/IHomePageUrlGenerator';
import OpenStreetMapMapSearchPlaceOpenStreetMapContainer from '../search/OpenStreetMapMapSearchPlaceOpenStreetMapContainer';
import styles from './MainMapSearchPlaceOpenStreetMap.module.scss';

interface MainMapSearchPlaceOpenStreetMapProps {
  urlGenerator: IHomePageUrlGenerator;
  mapAdapter: IMapAdapterOpenStreetMap | undefined;
}

// let renderCounter = 0;

const MainMapSearchPlaceOpenStreetMap: React.FC<MainMapSearchPlaceOpenStreetMapProps> = ({
  urlGenerator,
  mapAdapter
}: MainMapSearchPlaceOpenStreetMapProps) => {

  // console.debug(`Render MainMapSearchPlaceOpenStreetMap ${++renderCounter}`);

  const handleOnClickGetUserLocation = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        urlGenerator.redirectHomePageNoImmer((query) => {
          const result: DeepReadonly<HomePageQuery> = {
            ...query,
            map: {
              center: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              zoom: Math.max(12, mapAdapter?.getZoom() ?? 0),
            },
            location: undefined,
          };

          return result;
        });
      });
    }

    event.currentTarget.blur();
  }, [mapAdapter, urlGenerator]);

  return (
    <form id={styles['app-form']}>
      <OpenStreetMapMapSearchPlaceOpenStreetMapContainer
        urlGenerator={urlGenerator}
        mapAdapter={mapAdapter}
      />
      <button
        type='button'
        title='Wycentruj mapę dla mojej lokalizacji'
        className={styles['app-button']}
        onClick={handleOnClickGetUserLocation}
      />
    </form>
  );
};

export default MainMapSearchPlaceOpenStreetMap;
