import type { MapBoundsModel } from '../../models/system/map/MapBoundsModel';
import type { MapLatLngModel } from '../../models/system/map/MapLatLngModel';
import type { MapParamsQuery } from '../../models/system/map/queries/MapParamsQuery';
import type { IMapAdapter } from './interface/IMapAdapter';

class MapHelpers {

  private static notADiffLimit = Math.pow(10, -1);

  public static calculateMapParamsQueryDiff(queryA: MapParamsQuery | undefined, queryB: MapParamsQuery | undefined): MapParamsQuery {
    const zoom = (queryA?.zoom ?? 0) - (queryB?.zoom ?? 0);

    const center: MapLatLngModel = {
      lat: (queryA?.center?.lat ?? 0) - (queryB?.center?.lat ?? 0),
      lng: (queryA?.center?.lng ?? 0) - (queryB?.center?.lng ?? 0),
    };

    const bounds: MapBoundsModel | undefined = queryA?.bounds || queryB?.bounds
      ? {
        ne: {
          lat: (queryA?.bounds?.ne.lat ?? 0) - (queryB?.bounds?.ne.lat ?? 0),
          lng: (queryA?.bounds?.ne.lng ?? 0) - (queryB?.bounds?.ne.lng ?? 0),
        },
        sw: {
          lat: (queryA?.bounds?.sw.lat ?? 0) - (queryB?.bounds?.sw.lat ?? 0),
          lng: (queryA?.bounds?.sw.lng ?? 0) - (queryB?.bounds?.sw.lng ?? 0),
        },
      }
      : undefined;

    const diff: MapParamsQuery = {
      center: center,
      zoom: zoom,
      bounds: bounds,
    };

    return diff;
  }

  public static getMapParamsFromMapAdapter(mapAdapter: IMapAdapter): MapParamsQuery {
    const boundsSimple = mapAdapter.getBounds();
    const mapParamsQuery: MapParamsQuery = {
      center: mapAdapter.getCenter(),
      zoom: mapAdapter.getZoom(),
      bounds: {
        ne: {
          lat: boundsSimple.n,
          lng: boundsSimple.e,
        },
        sw: {
          lat: boundsSimple.s,
          lng: boundsSimple.w,
        },
      },
    };

    return mapParamsQuery;
  }

  public static isMapParamsSame(queryA: MapParamsQuery | undefined, queryB?: MapParamsQuery | undefined): boolean {
    const diff = MapHelpers.calculateMapParamsQueryDiff(queryA, queryB);

    return !MapHelpers.isMapParamsDiffAny(diff);
  }

  public static isMapParamsDiffAny(diff: MapParamsQuery): boolean {
    const isDiff = diff.center?.lat !== 0 ||
      diff.center?.lng !== 0 ||
      diff.zoom !== 0 ||
      diff.bounds?.ne.lat !== 0 ||
      diff.bounds?.ne.lng !== 0 ||
      diff.bounds?.sw.lat !== 0 ||
      diff.bounds?.sw.lng !== 0;

    return isDiff;
  }

  public static isMapParamsDiffSignificant(diff: MapParamsQuery): boolean {
    const isDiff = diff.center?.lat !== 0 ||
      diff.center?.lng !== 0 ||
      diff.zoom !== 0 ||
      (
        diff.bounds
          ? (
            Math.abs(diff.bounds?.ne.lat) > MapHelpers.notADiffLimit ||
            Math.abs(diff.bounds?.ne.lng) > MapHelpers.notADiffLimit ||
            Math.abs(diff.bounds?.sw.lat) > MapHelpers.notADiffLimit ||
            Math.abs(diff.bounds?.sw.lng) > MapHelpers.notADiffLimit
          )
          : false
      );

    return isDiff;
  }
}

export default MapHelpers;
