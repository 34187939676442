import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, type Path } from 'react-router-dom';
import type { DeepReadonly } from '../../framework/base/common/constants/types/UtilityTypes';
// import DebugHelper from '../../framework/base/common/helpers/DebugHelper';
import type { IHomePageUrlGenerator } from '../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type { LocationDetailWithGeoApiModel } from '../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemWithParentApiModel } from '../../framework/gis/common/models/api/location/LocationListItemWithParentApiModel';
import type { IMapAwareState } from '../../framework/gis/common/store/modules/map/models/IMapAwareState';
import type { FriendlyUrlRoutePredefinedQuery } from '../components/layout/routers/AppRouterFriendlyUrlRepository';
import type EntityTypeEnum from '../constants/enums/EntityTypeEnum';
import HomePageUrlQueryMapper from '../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../models/system/home/queries/HomePageQuery';

type HomePageUrlQueryRecipe = (draft: DeepReadonly<HomePageQuery>) => DeepReadonly<HomePageQuery>;

const getLocations = (location: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel): (LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel)[] => {
  const parents = location.parent
    ? getLocations(location.parent)
    : [];
  return [
    ...parents,
    location,
  ];
};

// TODO this hook is used twice - on home page and on main map

const mapper = HomePageUrlQueryMapper.factory();

// let renderCounter = 0;

const useHomePageUrlGenerator = (
  predefinedQuery: FriendlyUrlRoutePredefinedQuery | undefined,
  useFriendlyUrls: boolean,
  detailModeEntityType: EntityTypeEnum | undefined
): IHomePageUrlGenerator => {

  // console.debug(`Render useHomePageUrlGenerator ${++renderCounter}`);

  // const mapParams = useSelector((state: IMapAwareState): MapParamsQuery => state.map.params);
  const locationData = useSelector((state: IMapAwareState): LocationDetailWithGeoApiModel | undefined => state.mapLocation?.data);

  const location = useLocation();

  const navigate = useNavigate();

  const query: DeepReadonly<HomePageQuery> = useMemo<DeepReadonly<HomePageQuery>>(() => {
    const result = mapper.parse(location.search, predefinedQuery, detailModeEntityType);

    return result;
  }, [location.search, predefinedQuery, detailModeEntityType]);

  const queryForBuildingUrl: DeepReadonly<HomePageQuery> = useMemo<DeepReadonly<HomePageQuery>>(() => {
    const result = {
      ...query,
      location: locationData
        ? {
          id: locationData.id,
          slugs: getLocations(locationData).map((location) => location.localNameSlug),
        }
        : query.location,
    };

    // if (!result.map && mapParams) {
    //   const x = {
    //     ...result,
    //     map: {
    //       ...mapParams
    //     }
    //   };

    //   return x;
    // }

    return result;
  }, [locationData, query]);

  const generateHomePageUrlNoImmer = useCallback((
    recipe: HomePageUrlQueryRecipe,
    useFriendlyUrls2 = true,
  ): Path => {
    const productBase = recipe(queryForBuildingUrl);

    const result = mapper.generateHomePageFriendlyUrl(productBase, useFriendlyUrls && useFriendlyUrls2 ? undefined : location.pathname);

    return result;
  }, [location.pathname, queryForBuildingUrl, useFriendlyUrls]);

  const redirectHomePageNoImmer = useCallback((recipe: HomePageUrlQueryRecipe, replace = false): void => {
    const to = generateHomePageUrlNoImmer(recipe);

    // if (DebugHelper.isDebug()) {
    //   console.log('useHomePageUrlGenerator redirect TO', to);
    // }

    navigate(to, { replace: replace });
  }, [generateHomePageUrlNoImmer, navigate]);

  // useEffect(() => {
  //   console.warn('Changed query');
  // }, [location.search, predefinedQuery, detailModeEntityType]);

  // useEffect(() => {
  //   console.warn('Changed queryForBuildingUrl');
  // }, [locationData, query]);

  // useEffect(() => {
  //   console.warn('Changed generateHomePageUrlNoImmer');
  // }, [location.pathname, queryForBuildingUrl, useFriendlyUrls]);

  // useEffect(() => {
  //   console.warn('Changed redirectHomePageNoImmer');
  // }, [generateHomePageUrlNoImmer, navigate]);

  const result = useMemo(() => ({
    query,
    queryForBuildingUrl,
    mapper,
    generateHomePageUrlNoImmer,
    redirectHomePageNoImmer,
  }), [generateHomePageUrlNoImmer, query, queryForBuildingUrl, redirectHomePageNoImmer]);

  return result;
};

export type {
  HomePageUrlQueryRecipe
};

export default useHomePageUrlGenerator;
