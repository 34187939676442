import type { Evented, Map } from 'leaflet';
import MapListenerAdapter from './base/MapListenerAdapter';

class OpenStreetMapMapListenerAdapter extends MapListenerAdapter<Evented, Map> {

  protected eventType: string;
  protected handler: () => void;

  constructor(eventType: string, handler: () => void, adaptedListener: Evented, adaptedMap: Map) {
    super(adaptedListener, adaptedMap);

    this.eventType = eventType;
    this.handler = handler;
  }

  public remove = (): void => {
    this.adaptedListener.off(this.eventType, this.handler);
  };
}

export default OpenStreetMapMapListenerAdapter;
