import type { HomePageQuery } from '../../../../common/models/system/home/queries/HomePageQuery';
import type { DeepReadonly } from '../../../base/common/constants/types/UtilityTypes';
import type { IHomePageUrlGenerator } from '../hooks/IHomePageUrlGenerator';
import type { MapParamsQuery } from '../models/system/map/queries/MapParamsQuery';
import MapHelpers from './map/MapHelpers';
import type { IMapAdapter } from './map/interface/IMapAdapter';

const mapRedirect = (urlGenerator: IHomePageUrlGenerator, mapAdapter: IMapAdapter, replace: boolean): void => {
  const mapAdapterMapParamsQuery: MapParamsQuery = MapHelpers.getMapParamsFromMapAdapter(mapAdapter);
  const urlMapParamsQuery = urlGenerator.query.map;

  const diff = MapHelpers.calculateMapParamsQueryDiff(mapAdapterMapParamsQuery, urlMapParamsQuery);

  if (MapHelpers.isMapParamsDiffAny(diff)) {
    if (MapHelpers.isMapParamsDiffSignificant(diff)) {
      // if (DebugHelper.isDebug()) {
      //   console.debug(MapHelpersDebug.formatMapParamsQuery(diff), 'diff - important');
      //   console.debug(MapHelpersDebug.formatMapParamsQuery(mapAdapterMapParamsQuery), !replace ? 'Redirect' : 'Replace');
      // }

      urlGenerator.redirectHomePageNoImmer((draft) => {
        const result: DeepReadonly<HomePageQuery> = ({
          ...draft,
          // pagination: undefined,
          map: mapAdapterMapParamsQuery,
          location: replace
            ? draft.location
            : undefined,
        });

        return result;
      }, replace);
    } else {
      // if (DebugHelper.isDebug()) {
      //   console.debug(MapHelpersDebug.formatMapParamsQuery(diff), 'diff - not important');
      // }
    }
  }
};

export default mapRedirect;
