import React, { useCallback, useEffect, useState, type KeyboardEvent, type PropsWithChildren } from 'react';
import Spinner from '../../../../../../../base/common/components/shared/elements/Spinner';
import styles from './MapSearchPlaceInput.module.scss';

interface MapSearchPlaceInputProps {
  initialValue: string;
  isLoading: boolean;
  isDisabled: boolean;
  onChange: (value: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const MapSearchPlaceInput: React.FC<PropsWithChildren<MapSearchPlaceInputProps>> = ({
  initialValue,
  isLoading,
  isDisabled,
  children,
  onChange,
  onKeyDown
}: PropsWithChildren<MapSearchPlaceInputProps>) => {

  const [value, setValue] = useState(initialValue);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    onChange(event.target.value);
  }, [onChange]);

  const handleOnBlur = useCallback((): void => {
    window.setTimeout(() => {
      onChange('');
    }, 500);
  }, [onChange]);

  const handleOnClickClear = useCallback((): void => {
    setValue('');
    onChange('');
  }, [onChange]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <label
        className={styles['app-label']}
        htmlFor='searchTextField'
      >
        Szukaj
        <span className={styles['app-label-span']}>{' '}miejsca</span>
      </label>
      <div className={styles['app-div']}>
        <input
          id='searchTextField'
          type='text'
          value={value}
          placeholder='Miejscowość, ulica albo kod pocztowy'
          disabled={isDisabled}
          autoComplete='off'
          className={styles[(isLoading || value) ? 'app-input-is-loading' : 'app-input']}
          onChange={handleOnChange}
          onKeyDown={onKeyDown}
          onBlur={handleOnBlur}
        />
        {isLoading && (
          <span className={styles['app-span-is-loading']}>
            <Spinner className='spinner-border-sm' />
          </span>
        )}
        {!isLoading && value && (
          <button
            type="button"
            className={styles['app-button-clear']}
            onClick={handleOnClickClear}
          />
        )}
        {children}
      </div>
    </>
  );
};

export default MapSearchPlaceInput;
