import loadable from '@loadable/component';
import React from 'react';
import BaseGisEntityTypeEnum from '../../../../../../framework/gis/common/constants/enums/BaseGisEntityTypeEnum';
import type { IMapAdapterOpenStreetMap } from '../../../../../../framework/gis/common/helpers/map/interface/IMapAdapterOpenStreetMap';
import type { IHomePageUrlGenerator } from '../../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type EntityTypeEnum from '../../../../../constants/enums/EntityTypeEnum';

const MainMapMarkers = loadable(() => import(
  /* webpackChunkName: "map-main-markers" */
  './markers/MainMapMarkers'
));
const MainMapNeighborhoodForm = loadable(() => import(
  /* webpackChunkName: "map-main-neighborhood-form" */
  '../../../../../../framework/gis/common/components/shared/elements/map/main/form/MainMapNeighborhoodForm'
));
const MainMapForm = loadable(
  /* webpackChunkName: "map-main-form" */
  () => import('./form/MainMapForm')
);
const MainMapDetail = loadable(() => import(
  /* webpackChunkName: "map-main-detail" */
  '../../../../../../framework/gis/common/components/shared/elements/map/main/detail/MainMapDetail'
));
const MainMapList = loadable(() => import(
  /* webpackChunkName: "map-main-list" */
  '../../../../../../framework/gis/common/components/shared/elements/map/main/list/MainMapList'
));

interface MainMapShownProps {
  urlGenerator: IHomePageUrlGenerator;
  mapAdapter: IMapAdapterOpenStreetMap;
  formEntityType: EntityTypeEnum | BaseGisEntityTypeEnum | undefined;
  isDetail: boolean;
  onIsMabBussy: (isBussy: boolean) => void;
}

// let renderCounter = 0;

const MainMapShown: React.FC<MainMapShownProps> = ({
  urlGenerator,
  mapAdapter,
  formEntityType,
  isDetail,
  onIsMabBussy,
}: MainMapShownProps) => {

  // console.debug(`Render MainMapShown ${++renderCounter}`);

  return (
    <div>
      {formEntityType && formEntityType !== BaseGisEntityTypeEnum.Neighborhood && (
        <MainMapForm
          mapAdapter={mapAdapter}
          entityType={formEntityType}
        />
      )}
      {formEntityType === BaseGisEntityTypeEnum.Neighborhood && (
        <MainMapNeighborhoodForm mapAdapter={mapAdapter} />
      )}
      {!formEntityType && (
        <>
          <MainMapMarkers
            mapAdapter={mapAdapter}
            urlGenerator={urlGenerator}
          />
          {isDetail && (
            <MainMapDetail
              mapAdapter={mapAdapter}
              urlGenerator={urlGenerator}
            />
          )}
          {!isDetail && (
            <MainMapList
              mapAdapter={mapAdapter}
              urlGenerator={urlGenerator}
              onIsMabBussy={onIsMabBussy}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MainMapShown;
