import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import utilsCanUseDom from '../../../../../../framework/base/common/helpers/utils/utilsCanUseDom';
import MapContainerOpenStreetMapMain from '../../../../../../framework/gis/common/components/shared/elements/map/MapContainerOpenStreetMapMain';
import MapWrapper from '../../../../../../framework/gis/common/components/shared/elements/map/MapWrapper';
import MainMapErrorBoundary from '../../../../../../framework/gis/common/components/shared/elements/map/main/MainMapErrorBoundry';
import MainMapLoading from '../../../../../../framework/gis/common/components/shared/elements/map/main/MainMapLoading';
import MainMapSearchPlaceOpenStreetMap from '../../../../../../framework/gis/common/components/shared/elements/map/main/MainMapSearchPlaceOpenStreetMap';
import type BaseGisEntityTypeEnum from '../../../../../../framework/gis/common/constants/enums/BaseGisEntityTypeEnum';
import type OpenStreetMapMapAdapter from '../../../../../../framework/gis/common/helpers/map/OpenStreetMapMapAdapter';
import { mapSetShowMap } from '../../../../../../framework/gis/common/store/modules/map/mapReducer';
import type { IMapAwareState } from '../../../../../../framework/gis/common/store/modules/map/models/IMapAwareState';
import type EntityTypeEnum from '../../../../../constants/enums/EntityTypeEnum';
import useHomePageUrlGenerator from '../../../../../hooks/useHomePageUrlGenerator';
import type { FriendlyUrlRoutePredefinedQuery } from '../../../../layout/routers/AppRouterFriendlyUrlRepository';
import styles from './MainMapOptimized.module.scss';
import MainMapShown from './MainMapShown';
import MainMapShownActions from './MainMapShownActions';

interface MainMapOptimizedProps {
  predefinedQuery: FriendlyUrlRoutePredefinedQuery | undefined;
  detailModeEntityType?: EntityTypeEnum | undefined;
  formEntityType: EntityTypeEnum | BaseGisEntityTypeEnum | undefined;
  useFriendlyUrls: boolean;
  isDetail: boolean;
}

// let renderCounter = 0;

const MainMapOptimized: React.FC<MainMapOptimizedProps> = ({
  predefinedQuery,
  detailModeEntityType,
  formEntityType,
  useFriendlyUrls,
  isDetail,
}: MainMapOptimizedProps) => {

  const showMap = useSelector((state: IMapAwareState) => state.map.showMap);

  const [isMapBussy, setIsMapBussy] = useState(false);
  const [mapAdapter, setMapAdapter] = useState<OpenStreetMapMapAdapter | null>();

  const dispatch = useDispatch();

  const urlGenerator = useHomePageUrlGenerator(predefinedQuery, useFriendlyUrls, detailModeEntityType);

  useEffect(() => {
    dispatch(mapSetShowMap(true));

    return () => {
      dispatch(mapSetShowMap(false));
    };
  }, [dispatch, showMap]);

  useEffect(() => {
    document.body.parentElement?.classList.add('map');

    return () => {
      document.body.parentElement?.classList.remove('map');
    };
  });

  const handleSetMapIsBussy = useCallback((isBussy: boolean) => {
    // console.log('Set map is bussy', isBussy);

    setIsMapBussy(isBussy);
  }, []);

  // console.debug(`Render MainMapOptimized ${++renderCounter} params [${formEntityType}] state [isMapBussy: ${isMapBussy} mapAdapter: ${mapAdapter}]`);

  return (
    <>
      <MainMapSearchPlaceOpenStreetMap
        urlGenerator={urlGenerator}
        mapAdapter={mapAdapter ?? undefined}
      />
      <MapWrapper className={styles['app-main-map']}>
        <MainMapErrorBoundary>
          {utilsCanUseDom() && (
            <>
              <MapContainerOpenStreetMapMain
                urlGenerator={urlGenerator}
                ref={setMapAdapter}
              />
              {mapAdapter && (
                <>
                  <MainMapShownActions
                    urlGenerator={urlGenerator}
                    mapAdapter={mapAdapter}
                  />
                  <MainMapShown
                    urlGenerator={urlGenerator}
                    mapAdapter={mapAdapter}
                    formEntityType={formEntityType}
                    isDetail={isDetail}
                    onIsMabBussy={handleSetMapIsBussy}
                  />
                </>
              )}
            </>
          )}
          <MainMapLoading isLoading={isMapBussy || !mapAdapter} />
        </MainMapErrorBoundary>
      </MapWrapper>
    </>
  );
};

export default MainMapOptimized;
