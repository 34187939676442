import type { Map } from 'leaflet';
import type { MapBoundsSimpleModel } from '../../../models/system/map/MapBoundsSimpleModel';
import type { MapLatLngModel } from '../../../models/system/map/MapLatLngModel';
import type { IMapAdapter } from '../interface/IMapAdapter';
import type { IMapListenerAdapter } from '../interface/IMapListenerAdapter';

abstract class MapAdapter<
  T extends google.maps.Map | Map,
  TMapListenerAdapter extends IMapListenerAdapter,
> implements IMapAdapter {

  public abstract getCenter(): MapLatLngModel;
  public abstract getZoom(): number;
  public abstract getBounds(): MapBoundsSimpleModel;
  public abstract setCenter(center: MapLatLngModel): void;
  public abstract setZoom(zoom: number): void;
  public abstract fitToBounds(bounds: MapBoundsSimpleModel): void;
  public abstract remove(): void;
  public abstract onEvent(eventName: string, handler: () => void): TMapListenerAdapter;
  public abstract onCenterChanged(handler: () => void): IMapListenerAdapter;
  public abstract onZoomChanged(handler: () => void): TMapListenerAdapter;
  public abstract onMoved(handler: () => void): IMapListenerAdapter;

  protected adaptedMap: T;

  constructor(adaptedMap: T) {
    this.adaptedMap = adaptedMap;
  }

  public initEvents(): void {
    // Virtual method
  }

  public cleanupEvents(): void {
    // Virtual method
  }

  public getAdaptedMap = (): T => this.adaptedMap;

  public setCenterAndZoom = (center: MapLatLngModel, zoom?: number): void => {
    this.setCenter(center);

    if (zoom) {
      this.setZoom(zoom);
    }
  };
}

export default MapAdapter;
