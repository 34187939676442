let canUseDom: boolean | undefined = undefined;

function utilsCanUseDom(): boolean {
  if (canUseDom === undefined) {
    canUseDom = typeof window !== 'undefined' &&
      window.document !== undefined &&
      window.document.createElement !== undefined;
  }

  return canUseDom;
}

export default utilsCanUseDom;
