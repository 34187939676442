import React from 'react';
import Spinner from '../../../../../../../base/common/components/shared/elements/Spinner';
import styles from './MainMapLoading.module.scss';

interface MainMapLoadingProps {
  isLoading: boolean;
}

const MainMapLoading: React.FC<MainMapLoadingProps> = ({ isLoading }: MainMapLoadingProps) => {
  return (
    <div className={styles[isLoading ? 'app-main-map-loading-yes' : 'app-main-map-loading-no']}>
      <Spinner className='text-muted' />
      <p className={styles['app-main-map-loading-label']}>
        Ładowanie mapy...
      </p>
    </div>
  );
};

export default MainMapLoading;
