import type { IMapListenerAdapter } from '../interface/IMapListenerAdapter';

abstract class MapListenerAdapter<T, TMap> implements IMapListenerAdapter {

  public abstract remove(): void;

  protected adaptedListener: T;
  protected adaptedMap: TMap;

  constructor(adaptedListener: T, adaptedMap: TMap) {
    this.adaptedListener = adaptedListener;
    this.adaptedMap = adaptedMap;
  }

  public getAdaptedListener = (): T => this.adaptedListener;
}

export default MapListenerAdapter;
